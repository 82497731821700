import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import back from "./assets/back.svg";
import Authheader from "../layout/header/autheader";
import { Link, navigateTo } from "gatsby";

import { forgotPassword } from "../../api/actions/forgot-password";

export default function Verify() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState("");
  const [success, setsuccess] = React.useState("");

  const VerifySchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required"),
  });

  const onSubmitForm = async (data) => {
    setError("");
    setsuccess("");
    const result = await forgotPassword(data);
    console.log(result);
    if (result.data.data && result.data.data.message) {
      setsuccess(result.data.data.message);
      return;
    }
    setError("Server is down right now");
  };

  return (
    <>
      <Authheader />
      <div className="verify">
        <div className="arrowback">
          <Link to="/login">
            {" "}
            <img src={back} alt="back to login" />
          </Link>
        </div>{" "}
        <h1>Forgot password?/</h1>
        <p>Enter email below:</p>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={VerifySchema}
          onSubmit={(data) => {
            onSubmitForm(data);
          }}
        >
          {({ touched, errors }) => (
            <Form>
              <div className="form-group">
                <Field
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  className={`form-control ${
                    touched.email && errors.email ? "is-invalid" : ""
                    }`}
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="invalid-feedback"
                />
                {error && <p style={{ color: "red" }}>{error}</p>}
                {success && (
                  <div
                    className="sucess-custom"
                    style={{ paddingBottom: "20px" }}
                  >
                    {success}
                  </div>
                )}
                <button type="submit" className="next" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting...  " : "Recover Password"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
