import API from "../utils/axios";

export const forgotPassword = async (data) => {
  try {
    const response = await API.post("/forgot-password", data, {
      "Content-Type": "application/json",
    });
    return { success: 1, data: response };
  } catch (err) {
    return { success: 0, data: err.response };
  }
};
